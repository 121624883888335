'use client'

import ErrorBox from '@/app/error'
import type Error from 'next/error'

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { message?: string; digest?: string }
  reset: () => void
}) {
  return (
    <html>
      <body>
        <ErrorBox error={error} reset={reset} errorOnly={true} />
        <div>(global)</div>
      </body>
    </html>
  )
}
